.exercise-card {
  cursor: pointer;
  display: inline;
  margin: auto;
}

.blur {
  -webkit-filter: blur(4px);
}

.card-question {
  position: relative;
  display: block;
}
