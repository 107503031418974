html {
  height: 100%;
  background-color: #FFFFFF;
}
body {
  height: 100%;
}

#root {
  height: 100%;
}
