@font-face {
  font-family: 'Arial Rounded MT Bold';
  src: url(./assets/arialroundedmtbold.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: "arialroundedmtbold" -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', 'Tajawal', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-container > * {
  font-family: 'Roboto', 'Arial', sans-serif !important;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

* {
  font-family: 'Roboto', 'Tajawal', sans-serif;
}
.ant-layout,
.ant-typography,
.ant-menu,
.ant-tabs,
.ant-btn,
.ant-table {
  font-family: 'Roboto', 'Tajawal', sans-serif !important;
}
.text-custom-grey {
  /* font-weight: 400; */
  /* font-size          : 15; */
  color: #999999 !important;
}
.ant-tabs-nav {
  margin: 0 !important;
}

/* ::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  border-radius: 12px;
  width: 4px;
  background-color: #f5f5f5;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffd252;
} */
.progress {
  height: 0.25rem !important;
}
.progress-bar {
  background-color: #40b2f2 !important;
  /* position: relative; */
}
.progress-value {
  color: #40b2f2;
}
.feedback-background {
  background-color: #f2f7e6;
}
.homework-background {
  background-color: #fbf7eb;
}
/* .progress-bar::after {
  content: '20%';
  position: absolute;
  right: 0;
  top: 150;
  color:red;
} */

.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 5px !important;
}

.border {
  border: 1px solid rgb(241, 241, 241) !important;
}
