.welcome-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}
.report-title,
.pikado-image {
  display: block;
}

.header-date,
.welcome-title {
  /* font-size: 16px!important; */
}

.appBar {
  height: auto;
}

.pikado-image-mobile {
  display: none;
}
/* For tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .welcome-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: baseline;
  }
  .header-date,
  .welcome-title {
    font-size: 14px !important;
  }
  .report-title,
  .pikado-image {
    display: block;
  }
  .pikado-image-mobile {
    display: none;
  }
}

/* For mobile devices */
@media (max-width: 767px) {
  .welcome-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: baseline;
  }
  .header-date,
  .welcome-title {
    font-size: 12px !important;
  }
  .report-title,
  .pikado-image {
    display: none;
  }
  .pikado-image-mobile {
    display: block;
  }
}
