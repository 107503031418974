.login-page {
  width: 100%;
  // height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;
}
.form-right i {
  font-size: 100px;
}
.login_fields {
  text-align: left;
}
.form-check {
  text-align: left;
}
.form-right.h-100.bg-primary.text-white.text-center.pt-5 {
  border-radius: 4%;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: 50% !important;
  max-width: 50% !important;
}
// .login_fields {
//   height: 803px;
// }

@media only screen and (min-width: 768px) {
}
.padding_form {
  padding: 1.5rem;
}

input[type='checkbox'] {
  border: 1px solid #8ba54b !important;
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}
input[type='checkbox']:focus {
  outline: max(2px, 0.15em) solid #c3e56e;
  outline-offset: max(2px, 0.15em);
}

input[type='checkbox']:disabled {
  --form-control-color: #c3e56e;

  color: #c3e56e;
  cursor: not-allowed;
}
.form-check-input:checked {
  background-color: #fff;
  border-color: #c3e56e !important;
}
.haveanaccount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */

  text-align: center;

  color: #000000;
}
.haveanaccount span {
  color: #f2406a;
}
.Decoration_none {
  text-decoration: none;
}
.css-13cymwt-control {
  border: none !important;
}
.Termsancond {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align-last: center;
  padding: 0 38px;
  line-height: 14px;
  text-align: center;

  color: #a6a6a6;
}
.Termsancond span {
  color: #f2406a;
}
.bg_button {
  background: url('../../assets/Group218.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  font-weight: 400;
  background-position: center;

  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  background-position-x: center;
  height: 64px;
}
.item_center {
  text-align: center;
}
.btn:hover {
  color: #fff;
  background-color: var(--bs-btn-hover-bg);
  border-color: none;
}
.PhoneInputInput {
  border: none;
}

.form-control {
  background: #f7f7f7 !important;
  border-radius: 10px 0px 0px 10px;
  height: 59px;
}
.form-control > div {
  background-color: #f7f7f7;
}
.PhoneInput > input {
  background-color: #f7f7f7;
}

.input-group > .form-control::placeholder,
.input-group > .form-floating::placeholder,
.input-group > .form-select::placeholder {
  /* color: beige; */
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: #dbdbdb;
}
.modal-header {
  border: none !important;
}
.rounded {
  border-radius: 24px !important;
}
