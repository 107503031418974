.overlay {
  background: rgba(0, 0, 0, 0.7) !important;
  transition: opacity 500ms !important;
  //   visibility: hidden;
  //   opacity: 0;
  transition: all 5s ease-in-out !important;
}
.overlay:target {
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 5s ease-in-out !important;
}

.popup {
  // margin: 70px auto;
  // padding: 20px;
  // background: #fff;
  // border-radius: 5px;
  // width: 30%;
  // position: relative;
  //   opacity: 0;
  transition: all 5s ease-in-out !important;
}
// .popup:target {
//   // margin: 70px auto;
//   // padding: 20px;
//   // background: #fff;
//   // border-radius: 5px;
//   // width: 30%;
//   // position: relative;
//   opacity: 1;
//   transition: all 5s ease-in-out;
// }
.close {
  color: black;
}
.loader_page {
  width: 100%;
  z-index: 10;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.384);
  justify-content: space-around;
  align-items: center;
}

.loader_box {
  display: flex;

  background: rgba(0, 0, 0, 0.384);
  width: 300px;
  height: 180px;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
  border-radius: 10px;
}
.loader_desc {
  font-size: 10px;
  text-align: center;
}

.loader_title {
  font-size: 13px;
  font-weight: 600;
}
.loader_desc {
  font-size: 10px;
  text-align: center;
}

.sign-up-page-title {
  font-family: 'Roboto';
  text-align: center;
  margin-inline: auto;
}

.react-datepicker-wrapper {
  width: 50%;
}

.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 10px;
}

.custom-checkbox input[type='radio'] {
  display: none; /* Hide the default radio button for all options */
}

/* Image styling */
.custom-checkbox .image {
  width: 100px; /* Set the size of the image */
  height: 100px;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  display: block;
  border-radius: 50%;
  margin-bottom: 10px; /* Space for radio button below the image */
}

/* Radio button styling for images */
.custom-checkbox .radio-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: #f2406a;
  display: none;
}

.custom-checkbox input[type='radio']:checked + .image {
  border: 2px solid #f2406a; /* Border around selected image */
  border-radius: 50%;
}

.custom-checkbox input[type='radio']:checked + .image + .radio-button {
  border: 2px solid #f2406a; /* Highlight radio button under image */
  border-radius: 50%;
}

.custom-checkbox input[type='radio'] + .radio-button {
  display: block; /* Show radio button below image */
}

/* Text option styling */
.custom-checkbox .text-option {
  padding: 10px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

/* Visible radio button for text option */
// .custom-checkbox input[type="radio"]:checked + .text-option {
//   // border: 2px solid blue; /* Border around selected text */
// }

.custom-checkbox input[type='radio']:not(:checked) + .text-option::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  vertical-align: middle;
}

.custom-checkbox input[type='radio']:checked + .text-option::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #f2406a;
  background-color: #f2406a;
  vertical-align: middle;
}
div .CircularProgressbar .CircularProgressbar-path {
  stroke: #ffd252 !important;
}
.text-pikado-red {
  color: #f2406a;
}
