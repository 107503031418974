/*----  Main Style  ----*/
#cards_landscape_wrap-2 {
  text-align: center;
}
#cards_landscape_wrap-2 .container {
  padding-top: 0;
  padding-bottom: 20px;
}
#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}
#cards_landscape_wrap-2 .card-flyer {
  // border-radius: 20px;
}
#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
  // border-radius: 20px 20px 0px 0px;
}
#cards_landscape_wrap-2 .card-flyer .image-box img {
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  width: 100%;
  height: 220px;
}
#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}
#cards_landscape_wrap-2 .card-flyer .text-box {
  text-align: center;
}
#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
  padding: 1.6rem 1.125rem 0.938rem 1.125rem;
  min-height: 14rem;
}
#cards_landscape_wrap-2 .card-flyer {
  background: #f5f5f5;
  height: 90%;

  margin-top: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#cards_landscape_wrap-2 .card-flyer:hover {
  background: #f5f5f5;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-top: 50px;
}
#cards_landscape_wrap-2 .card-flyer .text-box p {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: left;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  min-height: 3.75rem;
}
#cards_landscape_wrap-2 .card-flyer .text-box h6 {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: left;
  color: #000000;
}

.btn-repeat {
  width: 100px;
  margin-top: 20px;
  height: 50px;
  background: #999999 !important;
  // border-radius: 50px !important;
  border: 6px solid #8d8d8d;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}
.btn-repeat span {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}

.btn-watch {
  width: 200px;
  margin-top: 20px;
  border-color: transparent;

  height: 50px;

  background: #f2406a !important;
  // border-radius: 50px !important;
  border: 6px solid #da2651;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}

.btn-watch span {
  font-size: 18px;
  line-height: 25px;

  text-align: center;

  color: #ffffff;
}

.header-top {
  border-bottom: 1px solid #c4c4c4;
  padding: 50px 0 13px 0;
}

.header-top-date {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
  color: #f2406a;
}
.modal {
  --bs-modal-width: 900px;
}

@media only screen and (max-width: 600px) {
}

.model_courses {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  border-radius: 15px;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1418px;
    height: 500px;
    margin-top: 11rem;
  }
  .img_course_model {
    // border-radius: 0px;
    width: 50%;
  }
}
.modal-content {
  border: none !important;
}
button.close {
  background: none !important;
  border: none;
  font-size: 29px;
}

.btn-record:hover {
  background: #999999 !important;
  border-color: #999999 !important;
}
.btn-record:hover {
  background: #40b2f2 !important;
  border-color: #40b2f2 !important;
}
button.btn-record.mt-5.btn {
  width: 208px;
}
.btn-record {
  width: 100%;
  height: 50px;
  background: #40b2f2 !important;
  // border-radius: 50px !important;
  border: 6px solid #3da7e3;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}
.btn-record:disabled {
  background: #8d8d8d !important;
  cursor: not-allowed;
}

/* Button styles for span */
.btn-record span {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}

/* Hover effect (only applies when button is not disabled) */
.btn-record:not(:disabled):hover {
  background: #40b2f2 !important;
  border-color: #40b2f2 !important;
}
.text-right {
  text-align: right;
}

.pragraph_record {
  font-weight: 700;
  font-size: 28px;
}
#myModalLabel {
  margin: auto;
}
.modal-body .sc-cwSeag.fYXoAP {
  text-align: center;
  margin: auto !important;
}
.card-flyer img {
  // border-radius: 20px 20px 0px 0px;
}

.image-box {
  position: relative;
}
.startbutton {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  right: 17px;
  top: 153px;
}

.ant-modal-title {
  text-align: center;
}
.ant-modal-body {
  text-align: center;
}

#audio {
}

.btn-test-record:hover {
  background: #f2406a !important;
  border-color: #da2651 !important;
}
button.btn-test-record.mt-5.btn {
  width: 208px;
}
.btn-test-record {
  width: 100%;
  height: 50px;
  background: #f2406a !important;
  // border-radius: 50px !important;
  border: 6px solid #da2651;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}
.btn-test-record span {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
}
.btn-test-record:hover {
  background: #f2406a !important;
  border-color: #da2651 !important;
}
.progress-bar[value]::-webkit-progress-value::after {
  content: '80%';
  position: absolute;
  right: 0;
  top: -125%;
  color: red;
  font-size: 32px;
}
