.signup-logo {
  width: 12rem;
}

.signup-card {
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}
.form-check-signup {
  padding-left: 0 !important;
}
.sign_up_title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */

  color: #f2406a;
}
@media (min-width: 768px) {
  .signup-card {
    width: 50%;
  }
}
