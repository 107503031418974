/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #cards_landscape_wrap-2 .container {
    padding-top: 0 !important; /**/
  }
  #cards_landscape_wrap-2 .card-flyer {
    margin-top: 25px;
  }
  .exercise-modal {
    width: 100% !important;
    padding: 10px;
  }
  .card-question {
    display: block;
  }
  .exercise-card {
    width: 90%;
  }

  .test-or-question-text {
    // margin-bottom: 15px;
    // margin-top: 15px;
    margin-left: 0 !important;
  }
  .ant-modal.css-dev-only-do-not-override-1n7nwfa.exercise-modal {
    width: 100% !important;
  }

  .Modal_Res_Test {
    display: flex;
    flex-direction: column;
  }
  .Modal_Res_Test span {
    position: unset !important;
  }
  .Modal_Res_Table {
    display: flex;
    flex-direction: column;
    height: fit-content;
    flex-flow: column !important;
    margin-top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-inline-start: auto;
  }
  .pronunciation-analysis-text {
    width: 150%;
  }
  .Thanks_Res_Modal {
    height: 100%;
    max-height: 100%;
    margin-left: 0 !important;
    margin-bottom: 20px;
  }
  .analysis-modal-wrapper {
    max-height: 90%;
    width: 100% !important;
  }

  .ant-modal.css-dev-only-do-not-override-1n7nwfa {
    max-height: 90%;
    width: 100% !important;
  }
  .Thanks_Res_Modal button {
    position: unset !important;
  }
  // .ant-modal-body {
  //   height: 500px;
  // }

  video {
    width: 100%;
  }
  canvas {
    text-align: center;
    width: 100% !important;
  }
  circle {
    width: 100% !important;
  }

  .login_fields {
    height: auto;
  }
  .bg_button {
    width: 17rem;
  }
  .btn:hover {
    color: #fff;
    background-color: none !important;
    border-color: none !important;
  }
  .css-13xfq8m-MuiTabPanel-root {
    padding: 0px !important;
  }
  .Termsancond {
    padding: 0 14px;
  }
  .recorder_contianer {
    width: 321px;
    height: 76px;
    border-radius: 11px;

    margin: auto;
    padding: 14px 0 0px 0;
  }
  .AudioPlayer_rec {
    width: 321px;
    padding: 3px 3px;

    margin: auto;
  }
  #cards_landscape_wrap-2 .card-flyer .text-box img {
    max-width: min-content;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .exercise-card {
    width: 50%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
